import { useTranslation } from 'next-i18next'

import { SkeletonItem, WrappedNodeDefault } from 'src/components/common'
import { Icon, Typography } from 'src/components/ui'

import { PlanForTodayMetrics } from 'src/models'

import { METRIC_ICONS, ternary } from 'src/utils'

type PlanMetricsProps = {
  data?: PlanForTodayMetrics | null
  loadingFetch?: boolean
}

export const PlanMetrics = ({ loadingFetch = false, data = null }: PlanMetricsProps) => {
  const { t } = useTranslation()

  return (
    <div className='flex gap-2'>
      {ternary(
        loadingFetch,
        <SkeletonItem type='metrics' />,
        <>
          {data &&
            Object.keys(data).map((key) => {
              const typeKey = key as keyof PlanForTodayMetrics

              return (
                <div className='basis-3/12' key={key}>
                  <div className='flex h-12 w-12 items-center justify-center rounded-full bg-gray-3'>
                    <Icon name={METRIC_ICONS[typeKey]} color='blue-gray-8' />
                  </div>
                  <div className='mt-2 grid'>
                    <Typography className='font-semibold'>{WrappedNodeDefault(data[typeKey])}</Typography>
                    <Typography variant='secondary' color='gray-7' className='truncate'>
                      {t(`plan-for-today:${key}`)}
                    </Typography>
                  </div>
                </div>
              )
            })}
        </>,
      )}
    </div>
  )
}
