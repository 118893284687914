import { Col, Row } from 'antd'
import cx from 'clsx'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { cond, equals } from 'ramda'

import { book } from 'src/init/book'

import { WrappedNodeDefault } from 'src/components/common'
import { Icon, ListItem, SwipeAction, Typography } from 'src/components/ui'

import { ClaimType } from 'src/models'

import { useDialog } from 'src/contexts'

import { GET_ENUMS, getClaimItemActions, rowGutters, ternary } from 'src/utils'

import st from './Styles.module.css'

type ClaimItemProps = {
  item: ClaimType
  customerId?: number
  onDelete: (id: number) => void
}

export const ClaimItem = ({ item, onDelete, customerId }: ClaimItemProps) => {
  const router = useRouter()
  const { setOnPageDialog } = useDialog()

  const pathToDetails = {
    pathname: book.claims,
    query: {
      id: item.id,
      returnId: router.query.id,
      customerId: router.query.customerId || customerId,
    },
  }

  const statusClass = cx(st['claim-status'], st[item.status])

  const onSelectCell = (type: string) => () =>
    cond([
      [
        equals('delete'),
        () => {
          if (item.isDeletable) {
            onDelete(item.id)
            setOnPageDialog(GET_ENUMS.alertDialog.confirm, true)
          }
        },
      ],
    ])(type)

  const claimActions = getClaimItemActions({ onSelectCell, item })

  const renderContent = (value?: string | number | null, span?: number) => {
    return (
      <Col span={span}>
        <Typography variant='secondary' color='gray-7'>
          {WrappedNodeDefault(value)}
        </Typography>
      </Col>
    )
  }

  const content = (
    <ListItem>
      <Link href={pathToDetails}>
        <Row align='middle' gutter={rowGutters}>
          <Col span={7}>
            <div className='flex items-center'>
              <div className={statusClass}>
                <Icon name='CompletedFill' />
              </div>
              <Typography variant='secondary' color='gray-7'>
                {WrappedNodeDefault(item.article)}
              </Typography>
            </div>
          </Col>
          {renderContent(item?.cause?.label, 5)}
          {renderContent(item.orderId, 4)}
          {renderContent(item?.quantity?.label, 3)}
          {renderContent(item.amount, 5)}
        </Row>
      </Link>
    </ListItem>
  )

  return (
    <div className={st['claim']}>
      {ternary(item.isDeletable, <SwipeAction rightActions={claimActions}>{content}</SwipeAction>, content)}
    </div>
  )
}
