import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

import { TotalItem, TotalItemLabel, TotalItemValue } from 'src/components/common'

import { useOrdersDetailsFetch } from 'src/bus/orders'

import { getTranslate } from 'src/utils'

export const OrdersTotal = () => {
  const router = useRouter()

  const orderId = Number(router.query.id)
  const customerId = Number(router.query.customerId)

  const { t } = useTranslation()
  const { ordersDetails, loadingOrdersDetailsFetch } = useOrdersDetailsFetch({
    orderId,
    customerId,
  })

  const getOrdersT = getTranslate('orders', t)

  return (
    <div className='grid grid-cols-[1fr_1fr_2fr] gap-2'>
      <TotalItem label={<TotalItemLabel>{getOrdersT('total_qty')}</TotalItemLabel>}>
        <TotalItemValue loadingFetch={loadingOrdersDetailsFetch} precision={0} isNumber>
          {ordersDetails?.totalQuantity}
        </TotalItemValue>
      </TotalItem>
      <TotalItem label={<TotalItemLabel>{getOrdersT('total_article')}</TotalItemLabel>}>
        <TotalItemValue loadingFetch={loadingOrdersDetailsFetch} precision={0} isNumber>
          {ordersDetails?.totalArticles}
        </TotalItemValue>
      </TotalItem>
      <TotalItem variant='amount' label={<TotalItemLabel>{getOrdersT('total_price')}</TotalItemLabel>}>
        <TotalItemValue loadingFetch={loadingOrdersDetailsFetch}>{ordersDetails?.price}</TotalItemValue>
      </TotalItem>
    </div>
  )
}
