//Components
import { Grid } from 'antd'
import cx from 'clsx'
import { PropsWithChildren } from 'react'

import { GutterType } from 'src/models'

import st from './Styles.module.css'

type ContainerProps = PropsWithChildren<{
  gutter?: GutterType
}>

export const Container = ({ children = null, gutter = 'none' }: ContainerProps) => {
  const { sm = false } = Grid.useBreakpoint()

  const containerStyle = cx(st.container, {
    [st.tablet]: sm,
    [st[`gutter-${gutter}`]]: gutter,
  })

  return <div className={containerStyle}>{children}</div>
}
