import { OrdersArticles, OrdersDetails } from 'src/components/common'

export const ViewOrdersDetails = () => {
  return (
    <div className='flex h-full flex-col py-2' data-test-id='orders-details'>
      <div className='mb-5'>
        <OrdersDetails />
      </div>
      <OrdersArticles />
    </div>
  )
}
