import { zodResolver } from '@hookform/resolvers/zod'
import { Dayjs } from 'dayjs'
import { useTranslation } from 'next-i18next'
import { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import useDeepCompareEffect from 'use-deep-compare-effect'

import { GroupVisitPlanActionsFields } from 'src/components/common'
import {
  Button,
  Typography,
  Form,
  FormControl,
  FormFields,
  FormField,
  FormItem,
  FormMessage,
  FormLabel,
} from 'src/components/ui'

import {
  FormSubmitFn,
  FormCreateVisitEnum,
  FormCreateVisitType,
  StaticDataItemType,
  VisitAvailableDateType,
  VisitActionsType,
  FormCreateVisitSubmitType,
  SelectItemAutocomplete,
} from 'src/models'

import { formShapes } from 'src/bus/forms'

import { formatDate, getHasNoData, messages, orNull } from 'src/utils'

type CreateVisitFormType = {
  initialValues?: { customerId: number | undefined } | null
  loadingCreate?: boolean
  loadingFetchActions: boolean
  loadingFetchCustomers: boolean
  loadingFetchDate: boolean
  onSubmit: FormSubmitFn<FormCreateVisitSubmitType>
  customers: StaticDataItemType[]
  dates?: VisitAvailableDateType | null
  actions: VisitActionsType
  onSelectCustomerId: (id: number) => void
  onSearchCustomers: (keyword: string) => void
}

export const CreateVisitForm = ({
  initialValues = null,
  onSubmit,
  loadingCreate,
  loadingFetchCustomers,
  loadingFetchDate,
  loadingFetchActions,
  customers,
  actions,
  dates,
  onSelectCustomerId,
  onSearchCustomers,
}: CreateVisitFormType) => {
  const { t } = useTranslation()

  const formProps = useForm<FormCreateVisitType>({
    defaultValues: { ...formShapes.createVisit.shape },
    resolver: zodResolver(formShapes.createVisit.schema(t)),
  })

  const { control, handleSubmit, setValue, setError, reset, watch } = formProps

  const onSubmitForm = handleSubmit(async (values) => {
    const updatedValues = {
      ...values,
      customer: customers.find((item) => item?.value === values.customer),
      actions: actions.items.filter((action) => values.actions.find((a) => a === action?.value)),
      date: formatDate(values.date, 'dashReversed'),
    } as FormCreateVisitSubmitType

    await onSubmit({ values: updatedValues, acts: { setError, reset } })
  })

  const fieldCustomer = watch('customer')

  const onSelectHandle = (_: any, data: SelectItemAutocomplete) => {
    if (data?.value) {
      setValue(FormCreateVisitEnum.customer, data.value)
      onSelectCustomerId(Number(data?.id))
    }
  }

  const values = useMemo(() => {
    const values = {} as FormCreateVisitType

    if (dates) {
      values.date = dates.date
    }

    if (fieldCustomer) {
      values.customer = fieldCustomer
    }

    if (initialValues) {
      values.customer = customers.find((c) => c?.id === initialValues.customerId)?.value || ''
    }

    return values
  }, [dates, customers, initialValues, fieldCustomer])

  useDeepCompareEffect(() => {
    if (values) {
      reset({ ...values })
    }
  }, [values, reset])

  return (
    <Form {...formProps}>
      <FormFields.FormGeneral
        onSubmit={onSubmitForm}
        footer={
          <Button block type='submit' loading={loadingCreate} data-test-id='create-visit-submit'>
            {t('buttons:save')}
          </Button>
        }
      >
        <FormField
          control={control}
          name={FormCreateVisitEnum.customer}
          render={({ field }) => (
            <FormItem>
              <FormLabel className='mb-2'>
                <Typography className='font-semibold'>{t('visits:create_visit.customer')}</Typography>
              </FormLabel>
              <FormControl>
                <FormFields.InputAutoComplete
                  {...field}
                  withNoData
                  placeholder={t(messages.customer.placeholder)}
                  disabled={loadingCreate}
                  options={customers}
                  loading={loadingFetchCustomers}
                  onSearch={onSearchCustomers}
                  onSelect={onSelectHandle}
                />
              </FormControl>
              <FormMessage className='absolute' />
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name={FormCreateVisitEnum.date}
          render={({ field }) => (
            <FormItem>
              <FormLabel className='mb-2'>
                <Typography className='font-semibold'>{t('visits:create_visit.date_of_visit')}</Typography>
              </FormLabel>
              <FormControl>
                <FormFields.InputDatePicker
                  {...field}
                  data-test-id='create-visit-date'
                  placeholder={t(messages.customer.placeholder)}
                  allowClear={false}
                  disabledDate={(d: Dayjs) => {
                    return (
                      d.isBefore(dates?.dateRange?.start) ||
                      d.isAfter(dates?.dateRange?.end) ||
                      dates?.excludedDates?.includes(formatDate(d, 'dashReversed')) ||
                      false
                    )
                  }}
                  loading={loadingFetchDate}
                  hasDisabled={!fieldCustomer || loadingCreate}
                />
              </FormControl>
              <FormMessage className='absolute' />
            </FormItem>
          )}
        />
        <FormLabel className='mb-2'>
          <Typography className='font-semibold'>{t('visits:create_visit.actions')}</Typography>
        </FormLabel>
        {orNull(
          getHasNoData(actions.items),
          <div className='mb-2'>
            <Typography variant='secondary' color='gray-7'>
              {t('common:errors_no_data')}
            </Typography>
          </div>,
        )}
        <GroupVisitPlanActionsFields
          name={FormCreateVisitEnum.actions}
          options={actions.items}
          control={control}
          loading={loadingFetchActions}
          hasDisabled={loadingCreate}
        />
        <FormField
          control={control}
          name={FormCreateVisitEnum.comment}
          render={({ field }) => (
            <FormItem gutter='none'>
              <FormControl>
                <FormFields.InputTextArea
                  {...field}
                  placeholder={t(messages.createVisitComment.placeholder)}
                  disabled={loadingCreate}
                  rows={3}
                />
              </FormControl>
              <FormMessage className='absolute' />
            </FormItem>
          )}
        />
      </FormFields.FormGeneral>
    </Form>
  )
}
