import { TextArea as AntMobileTextarea } from 'antd-mobile'
import { TextAreaProps as AntMobileTextAreaProps, TextAreaRef } from 'antd-mobile/es/components/text-area'
import cx from 'clsx'
import { forwardRef } from 'react'

import st from './Styles.module.css'

export type TextareaProps = AntMobileTextAreaProps & {
  className?: string
}

export const InputTextArea = forwardRef<TextAreaRef, TextareaProps>(
  ({ className = '', placeholder = 'Textarea', disabled = false, ...restProps }, ref) => {
    const textareaStyle = cx(st['textarea'], className)

    return (
      <AntMobileTextarea
        className={textareaStyle}
        ref={ref}
        disabled={disabled}
        placeholder={placeholder}
        {...restProps}
      />
    )
  },
)

InputTextArea.displayName = 'InputTextArea'
