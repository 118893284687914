import { cva, VariantProps } from 'class-variance-authority'
import * as React from 'react'

import { cn } from 'src/utils'

const typographyVariants = cva('font-[Inter] block [overflow-wrap:anywhere]', {
  variants: {
    variant: {
      default: 'text-base leading-6',
      head: 'text-2xl font-semibold',
      title: 'text-lg leading-[22px] font-semibold',
      secondary: 'text-sm leading-[22px]',
      caption: 'text-xs leading-5',
    },
    color: {
      default: 'text-text',
      red: 'text-red-500',
      'blue-7': 'text-blue-7',
      'blue-8': 'text-blue-8',
      'blue-gray-9': 'text-blue-gray-9',
      'gray-5': 'text-gray-5',
      'gray-6': 'text-gray-6',
      'gray-7': 'text-gray-7',
      'gray-10': 'text-gray-10',
      white: 'text-white',
      error: 'text-error',
      info: 'text-states-info',
      success: 'text-states-success',
    },
  },
  defaultVariants: {
    variant: 'default',
    color: 'default',
  },
})

export type TTypographyColors = NonNullable<VariantProps<typeof typographyVariants>['color']>

export type TypographyProps = React.HTMLAttributes<HTMLDivElement> &
  VariantProps<typeof typographyVariants> & {
    tag?: 'span' | 'div' | 'p'
    className?: string
  }

export const Typography = React.forwardRef<HTMLDivElement, TypographyProps>(
  ({ tag: Tag = 'span', children, variant, color, className = '', ...props }, ref) => {
    const _color = typeof children === 'string' && children === 'NULL' ? 'red' : color

    return (
      <Tag className={cn(typographyVariants({ variant, color: _color, className }))} ref={ref} {...props}>
        {children}
      </Tag>
    )
  },
)
