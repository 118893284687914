import { useTranslation } from 'next-i18next'

import { ProgressBar, Typography, Skeleton } from 'src/components/ui'

import { CustomerLoyaltyType } from 'src/models'

import { useCurrencyFormat } from 'src/hooks'

import { formatDate, getColorType, getPercent, ternary } from 'src/utils'

type LoyaltyProgramProgressBarProps = {
  value: CustomerLoyaltyType
  loadingFetch: boolean
}

export const LoyaltyProgramProgressBar = ({ value = null, loadingFetch }: LoyaltyProgramProgressBarProps) => {
  const { t } = useTranslation()
  const { currencyFormat } = useCurrencyFormat({ precision: 0 })

  return (
    <>
      {ternary(
        loadingFetch,
        <>
          <Skeleton className='mb-4 h-4' />
          <Skeleton className='h-4' />
        </>,
        ternary(
          value,
          <>
            <div className='mb-1 flex items-center justify-between gap-2'>
              <Typography variant='secondary'>{currencyFormat(value?.minScale)}</Typography>
              <Typography variant='secondary'>{currencyFormat(value?.maxScale)}</Typography>
            </div>
            <ProgressBar
              percent={getPercent(value?.currentAmount, value?.maxScale)}
              colorType={getColorType(value?.nextStatus as string)}
            />
            <Typography variant='caption' color='gray-6'>
              {t('common:modal_customer_card.loyalty_left', {
                value: currencyFormat(value?.diff),
                status: value?.nextStatus,
                date: formatDate(value?.dateEnd, 'slash'),
              })}
            </Typography>
          </>,
          <Typography variant='secondary'>{t('common:modal_customer_card.no_loyalty')}</Typography>,
        ),
      )}
    </>
  )
}
