import { useTranslation } from 'next-i18next'

import { Dialog, DialogViewsSwitcher } from 'src/components/common'
import { Button, Typography } from 'src/components/ui'

import { useVisitsDetailsFetch } from 'src/bus/visits'

import { useDialog } from 'src/contexts'

import { GET_ENUMS, orNull } from 'src/utils'

export const CustomersComment = () => {
  const { t } = useTranslation()
  const { onPageDialogs, setOnPageDialog } = useDialog()
  const { visitsDetails, loadingVisitsFetch } = useVisitsDetailsFetch()

  const comment = visitsDetails?.comment || ''

  return orNull(
    comment,
    <>
      <Dialog
        open={onPageDialogs[GET_ENUMS.dialog.info]?.visible}
        onOpenChange={(open) => setOnPageDialog(GET_ENUMS.dialog.info, open)}
      >
        <DialogViewsSwitcher
          name={GET_ENUMS.dialog.info}
          params={{
            title: t('visits:comment_for', { title: visitsDetails?.companyName }) as string,
            description: comment,
          }}
        />
      </Dialog>
      <div className='flex flex-col gap-2'>
        <Typography className='font-semibold'>{t('visits:comment')}</Typography>
        <Button
          variant='card'
          disabled={loadingVisitsFetch}
          onClick={() => setOnPageDialog(GET_ENUMS.dialog.info, true)}
        >
          <Typography variant='secondary' className='truncate'>
            {comment}
          </Typography>
          <Typography variant='secondary' color='blue-7'>
            {t('buttons:see_more')}
          </Typography>
        </Button>
      </div>
    </>,
  )
}
