import { cva, VariantProps } from 'class-variance-authority'
import cn from 'clsx'
import * as React from 'react'

import { Skeleton, Typography } from 'src/components/ui'

import { useCurrencyFormat } from 'src/hooks'

const totalItemVariants = cva('grid items-center bg-white rounded-sm p-2 gap-0.5', {
  variants: {
    variant: {
      default: 'grid-cols-2',
      amount: 'grid-cols-[1fr_2fr]',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
})

const TotalItemLabel = ({ children }: { children: React.ReactNode }) => {
  return (
    <Typography variant='caption' color='gray-7'>
      {children}
    </Typography>
  )
}

TotalItemLabel.displayName = 'TotalItemLabel'

type TotalItemValueProps = {
  loadingFetch?: boolean
  isNumber?: boolean
  precision?: number
  children?: number | null
}

const TotalItemValue = ({
  isNumber = false,
  precision = 2,
  loadingFetch = false,
  children = 0,
}: TotalItemValueProps) => {
  const { currencyFormat } = useCurrencyFormat()

  const currencyOptions = { precision, ...(isNumber && { pattern: '#', negativePattern: '-#' }) }

  if (loadingFetch) {
    return <Skeleton className='h-6' />
  }

  return <Typography className='text-right font-semibold'>{currencyFormat(children, currencyOptions)}</Typography>
}

TotalItemValue.displayName = 'TotalItemValue'

export type TotalItemProps = React.HTMLAttributes<HTMLDivElement> &
  VariantProps<typeof totalItemVariants> & { label: React.ReactNode }

const TotalItem = React.forwardRef<HTMLDivElement, TotalItemProps>(
  ({ variant, children, label, className, ...props }, ref) => {
    return (
      <div className={cn(totalItemVariants({ variant, className }))} ref={ref} {...props}>
        {label}
        {children}
      </div>
    )
  },
)

TotalItem.displayName = 'TotalItem'

export { TotalItem, TotalItemLabel, TotalItemValue }
