import { zodResolver } from '@hookform/resolvers/zod'
import { useTranslation } from 'next-i18next'
import { useForm } from 'react-hook-form'

import { Button, Form, FormFields, FormItem, FormControl, FormField, FormMessage } from 'src/components/ui'

import { FormSubmitFn, AuthLoginType, FormSigninEnum } from 'src/models'

import { formShapes } from 'src/bus/forms'

import { messages, verifyEnvironment } from 'src/utils'

type SigninFormType = {
  loadingCreate?: boolean
  onSubmit: FormSubmitFn<AuthLoginType>
}

export const SigninForm = ({ loadingCreate = false, onSubmit }: SigninFormType) => {
  const { t } = useTranslation()

  const defaultCredentials = verifyEnvironment().isLocal
    ? { login: process.env.NEXT_PUBLIC_LOGIN, password: process.env.NEXT_PUBLIC_PASSWORD }
    : {}

  const formProps = useForm<AuthLoginType>({
    defaultValues: { ...formShapes.signin.shape, ...defaultCredentials },
    resolver: zodResolver(formShapes.signin.schema(t)),
  })

  const { control, handleSubmit, setError, reset } = formProps

  const onSubmitForm = handleSubmit(async (values) => {
    await onSubmit({ values, acts: { setError, reset } })
  })

  return (
    <Form {...formProps}>
      <FormFields.FormGeneral onSubmit={onSubmitForm}>
        <FormField
          control={control}
          name={FormSigninEnum.login}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <FormFields.InputBase {...field} placeholder={t(messages.login.placeholder)} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name={FormSigninEnum.password}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <FormFields.InputPassword {...field} placeholder={t(messages.password.placeholder)} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button block type='submit' loading={loadingCreate} data-test-id='sign-in'>
          {t('buttons:sign_in')}
        </Button>
      </FormFields.FormGeneral>
    </Form>
  )
}
