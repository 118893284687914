import { cva, VariantProps } from 'class-variance-authority'
// import * as iconsCustom from 'public/assets/icons'
import { default as iconSpriteStaticImg } from 'public/assets/icons/sprite.svg'
import React from 'react'

import { cn, dashCase } from 'src/utils'

const iconSprite = iconSpriteStaticImg as { src: string; width: number; height: number }
const iconSpriteUrl = iconSprite.src

const iconVariants = cva('inline-flex align-middle', {
  variants: {
    variant: {
      default: '',
      rotate: 'animate-spin',
    },
    color: {
      white: 'fill-white',
      error: 'fill-states-error',
      done: 'fill-status-done',
      'orange-8': 'fill-orange-8',
      'blue-7': 'fill-blue-7',
      'blue-8': 'fill-blue-8',
      'gray-3': 'fill-gray-3',
      'gray-5': 'fill-gray-5',
      'gray-7': 'fill-gray-7',
      'gray-10': 'fill-gray-10',
      'blue-gray-4': 'fill-blue-gray-4',
      'blue-gray-7': 'fill-blue-gray-7',
      'blue-gray-8': 'fill-blue-gray-8',
      'blue-gray-85': 'fill-blue-gray-85',
    },
  },
  defaultVariants: {
    variant: 'default',
    color: 'blue-gray-7',
  },
})

export type TIconColors = NonNullable<VariantProps<typeof iconVariants>['color']>

export type IconProps = React.HTMLAttributes<SVGSVGElement> &
  VariantProps<typeof iconVariants> & {
    name?: string
    width?: number
    height?: number
    className?: string
    rotate?: boolean
  }

const Icon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ name = '', width = 24, height = 24, className = '', color = 'error', rotate = false, ...props }, ref) => {
    const iconFileName = dashCase(name)

    // const hasName = name in iconsCustom
    //
    // if (!hasName) {
    //   console.warn(`The icon ${name} does not exist in the list`)
    //   return null
    // }

    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={width}
        height={height}
        viewBox='0 0 24 24'
        className={cn(iconVariants({ color, className, variant: rotate ? 'rotate' : 'default' }))}
        ref={ref}
        {...props}
      >
        <use xlinkHref={`${iconSpriteUrl}#${iconFileName}`} />
      </svg>
    )
  },
)

Icon.displayName = 'Icon'

export { Icon }
