import { useTranslation } from 'next-i18next'

import { CellCopy, VerifyVatInfoForm, WrappedNodeDefault } from 'src/components/common'
import { DialogContent, DialogHeader, Icon, Skeleton, Typography } from 'src/components/ui'

import { useCustomersVatFetch, useCustomersVatVerify } from 'src/bus/customers'

import { GET_ENUMS, orNull, ternary } from 'src/utils'

type DialogVatInformationProps = {
  title?: string
}

const vatStatusesText: Record<string, { title: string; color: 'info' | 'error' | 'success' }> = {
  idle: {
    title: 'customers:vat_info_modal.idle_status',
    color: 'info',
  },
  valid: {
    title: 'customers:vat_info_modal.valid_status',
    color: 'success',
  },
  invalid: {
    title: 'customers:vat_info_modal.invalid_status',
    color: 'error',
  },
}

export const DialogVatInformation = ({ title }: DialogVatInformationProps) => {
  const { t } = useTranslation()
  const { customersVatInfo, loadingCustomersVatInfo } = useCustomersVatFetch()
  const { onVerifyCustomersVat, loadingCustomersVatVerify } = useCustomersVatVerify()

  const copyAllText = `${t('customers:vat_info_modal.legal_name')}: ${
    customersVatInfo?.legalName
  }\n${t('customers:vat_info_modal.legal_address')}: ${customersVatInfo?.legalAddress}\n${t(
    'customers:vat_info_modal.vat_id',
  )}: ${customersVatInfo?.vat}`

  const vatInfoStatus = (
    <div className='mt-1 text-right'>
      <Typography variant='caption' color={vatStatusesText[customersVatInfo?.status || 'idle'].color}>
        {t(vatStatusesText[customersVatInfo?.status || 'idle'].title)}
      </Typography>
    </div>
  )

  return (
    <DialogContent name={GET_ENUMS.dialog.vatInformation}>
      <DialogHeader>
        <CellCopy copyValue={copyAllText} copiedText='all_info_copied' loadingFetch={loadingCustomersVatInfo}>
          <div className='flex items-center justify-center gap-1'>
            <Typography variant='title' color='gray-10'>
              {t('customers:vat_info_modal.title', { name: title })}
            </Typography>
            {orNull(
              customersVatInfo?.legalName && customersVatInfo?.legalAddress,
              <Icon name='Copy' color='gray-10' />,
            )}
          </div>
        </CellCopy>
      </DialogHeader>
      <Typography color='gray-10' className='font-semibold'>
        {t('customers:vat_info_modal.general')}
      </Typography>
      <div className='flex items-center justify-between overflow-hidden'>
        <div className='flex w-5/12'>
          <Typography variant='secondary' className='truncate'>
            {t('customers:vat_info_modal.legal_name')}
          </Typography>
        </div>
        <div className='flex w-7/12 flex-col'>
          <CellCopy
            value={customersVatInfo?.legalName}
            copyValue={customersVatInfo?.legalName}
            disabled={loadingCustomersVatInfo || !customersVatInfo?.legalName}
            copiedText='legal_name_copied'
            loadingFetch={loadingCustomersVatInfo}
            className='px-4 py-2'
          />
        </div>
      </div>
      <div className='flex items-center justify-between'>
        <div className='flex w-5/12'>
          <Typography variant='secondary'>{t('customers:vat_info_modal.legal_address')}</Typography>
        </div>
        <div className='flex w-7/12 flex-col'>
          {ternary(
            loadingCustomersVatInfo,
            <Skeleton className='h-[38px]' />,
            <div className='rounded-sm bg-gray-2 px-4 py-2'>
              <Typography variant='secondary'>{WrappedNodeDefault(customersVatInfo?.legalAddress)}</Typography>
            </div>,
          )}
        </div>
      </div>
      <VerifyVatInfoForm
        onSubmit={onVerifyCustomersVat}
        initialValues={{ vat: customersVatInfo?.vat || '' }}
        vatInfoStatus={vatInfoStatus}
        loadingFetch={loadingCustomersVatInfo}
        loadingUpdate={loadingCustomersVatVerify}
      />
    </DialogContent>
  )
}
