import cx from 'clsx'
import { Fragment, PropsWithChildren, ComponentType, ReactNode } from 'react'

import { Page } from 'src/components/common'

import { useOrientation } from 'src/hooks'

import st from './Styles.module.css'

type LayoutProps = {
  children: ReactNode
  pageTitle?: string
  enableOrientation?: boolean
  header?: ReactNode
  footer?: ReactNode
  contextProvider?: ComponentType<PropsWithChildren>
}

export const Layout = ({ children, pageTitle, enableOrientation, contextProvider, header, footer }: LayoutProps) => {
  const { isPortrait, isLandscape } = useOrientation()

  const mainStyles = cx(st['main'], {
    [st['landscape']]: enableOrientation && isLandscape,
    [st['portrait']]: enableOrientation && isPortrait,
  })

  const Context = contextProvider ? contextProvider : Fragment

  return (
    <Page title={pageTitle}>
      <div className='relative mx-auto flex h-[100svh] min-h-[100svh] flex-col bg-gray-1'>
        <Context>
          {header}
          <main className={mainStyles}>
            <section className='flex h-full flex-col'>{children}</section>
          </main>
          <footer className='relative z-10 w-full'>{footer}</footer>
        </Context>
      </div>
    </Page>
  )
}
