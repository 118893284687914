import { SwipeAction as AntMobileSwipeAction } from 'antd-mobile'
import cx from 'clsx'
import { PropsWithChildren } from 'react'

import { SwipeActionType } from 'src/models'

import st from './Styles.module.css'

type SwipeActionProps = PropsWithChildren<{
  leftActions?: SwipeActionType[]
  rightActions?: SwipeActionType[]
  className?: string
}>

export const SwipeAction = ({ children, leftActions, rightActions, className }: SwipeActionProps) => {
  const SwipeActionClass = cx(st['swipe-action'], className)

  return (
    <AntMobileSwipeAction leftActions={leftActions} rightActions={rightActions} className={SwipeActionClass}>
      {children}
    </AntMobileSwipeAction>
  )
}
