import cx from 'clsx'
import { useTranslation } from 'next-i18next'

import { ReturnSignatureForm, ReturnsTotal } from 'src/components/common'
import { Button } from 'src/components/ui'

import { useReturnsConfirm } from 'src/bus/returns'

import { useOrientation } from 'src/hooks'

import { useDrawer } from 'src/contexts'

import { GET_ENUMS, orNull } from 'src/utils'

import st from './Styles.module.css'

export const ReturnApprove = () => {
  const { t } = useTranslation()
  const { setOnPageDrawer } = useDrawer()
  const { onConfirmReturn, loadingReturnsConfirm } = useReturnsConfirm()
  const orientation = useOrientation()

  const classes = cx(st['return-approve'], {
    [st.landscape]: orientation.isLandscape,
    [st.portrait]: orientation.isPortrait,
  })

  return (
    <div className={classes}>
      <div className={st['return-approve-total']}>
        {orNull(
          orientation.isLandscape,
          <Button onClick={() => setOnPageDrawer(GET_ENUMS.drawer.confirmList, true)}>{t('show_list')}</Button>,
        )}
        <ReturnsTotal />
      </div>
      <ReturnSignatureForm onSubmit={onConfirmReturn} loadingCreate={loadingReturnsConfirm} />
    </div>
  )
}
