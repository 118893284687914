import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'

import { cn } from 'src/utils'

const loadingVariants = cva('rounded-full border-2 animate-spin border-[inherit]', {
  variants: {
    variant: {
      default: 'border-primary-foreground border-b-transparent',
      outline: 'border-secondary-foreground border-b-transparent',
      ghost: 'border-secondary-foreground border-b-transparent',
      cta: 'border-white border-b-transparent',
      warning: 'border-white border-b-transparent',
      orange: 'border-white border-b-transparent',
      success: 'border-secondary-foreground border-b-transparent',
      link: '',
      none: '',
      navbar: 'border-secondary-foreground border-b-transparent',
      card: 'border-secondary-foreground border-b-transparent',
    },
    size: {
      default: 'w-4 h-4',
      md: 'w-4 h-4',
      sm: 'w-3 h-3',
      icon: 'h-4 w-4',
      'icon-md': 'h-4 w-4',
      'icon-md-plus': 'h-4 w-4',
      'icon-sm': 'h-3 w-3',
    },
  },
  defaultVariants: {
    variant: 'default',
    size: 'default',
  },
})

export type LoadingProps = React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof loadingVariants>

const Loading = React.forwardRef<HTMLDivElement, LoadingProps>(({ variant, size, className, ...props }, ref) => {
  return (
    <div className='absolute inline-flex items-center'>
      <div className={cn(loadingVariants({ variant, size, className }))} ref={ref} {...props} />
    </div>
  )
})

Loading.displayName = 'Loading'

const buttonVariants = cva(
  "relative overflow-hidden inline-flex items-center justify-center whitespace-nowrap rounded-sm font-['Montserrat'] transition-colors duration-200 ease-in-out disabled:pointer-events-none disabled:bg-disabled-bg disabled:border-disabled-bg disabled:text-disabled-text",
  {
    variants: {
      variant: {
        default:
          'bg-primary text-primary-foreground hover:bg-primary-hover focus:bg-primary-focused active:bg-primary-pressed',
        outline:
          'border border-secondary-foreground text-secondary-foreground bg-secondary hover:bg-secondary-hover focus:bg-secondary-focused active:bg-secondary-pressed',
        ghost:
          'text-secondary-foreground bg-transparent hover:bg-secondary-hover focus:bg-secondary-focused active:bg-secondary-pressed',
        navbar:
          'hover:bg-secondary-hover focus:bg-secondary-focused active:bg-secondary-pressed disabled:bg-transparent disabled:opacity-40',
        cta: 'bg-cta text-white hover:bg-cta-hover focus:bg-cta-focused active:bg-cta-pressed/50',
        warning:
          'bg-warning text-white hover:bg-warning-hover/75 focus:bg-warning-focused/75 active:bg-warning-pressed/50',
        orange: 'bg-orange text-white hover:bg-orange-hover/75 focus:bg-orange-focused/75 active:bg-orange-pressed/50',
        success: 'bg-success text-cta-foreground hover:bg-success/75 focus:bg-success/75 active:bg-success-pressed/50',
        link: 'text-primary underline-offset-4 hover:underline',
        card: 'flex justify-between border rounded w-full py-2 pr-3 pl-4 bg-white hover:bg-secondary-hover focus:bg-secondary-focused active:bg-secondary-pressed',
        none: 'px-0',
      },
      size: {
        default: 'h-10 px-4 py-2 text-[16px] leading-[22px]',
        md: 'h-8 px-4 text-[14px] leading-[20px]',
        sm: 'h-6 px-2 text-[14px] leading-[18px]',
        icon: 'h-10 w-10',
        'icon-md-plus': 'h-9 w-9',
        'icon-md': 'h-8 w-8',
        'icon-sm': 'h-6 w-6',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
)

export type TButtonVariants = VariantProps<typeof buttonVariants>['variant']

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonVariants> & {
    asChild?: boolean
    loading?: boolean
    block?: boolean
  }

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, block = false, loading = false, asChild = false, children, ...rest }, ref) => {
    const Comp = asChild ? Slot : 'button'

    const loadingRef = React.useRef<HTMLDivElement>(null)

    const btnCls = cn({ 'opacity-40 pointer-events-none': loading, 'w-full': block }, className)

    return (
      <Comp className={cn(buttonVariants({ variant, size, class: btnCls }))} ref={ref} {...rest}>
        {loading ? (
          <span className='flex items-center justify-center'>
            <Loading variant={variant} size={size} ref={loadingRef} />
          </span>
        ) : (
          children
        )}
      </Comp>
    )
  },
)

Button.displayName = 'Button'

export { Button, buttonVariants }
