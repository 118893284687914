import { OverlayView } from '@react-google-maps/api'
import cx from 'clsx'
import { useMemo } from 'react'

import { Icon, Typography } from 'src/components/ui'
import { TIconColors } from 'src/components/ui/Icon'
import { TTypographyColors } from 'src/components/ui/Typography'

import { ManagePointType } from 'src/models'

import { getPixelPositionOffset, MAX_MARKER_WIDTH, orNull } from 'src/utils'

type CustomMarkerProps = {
  marker: ManagePointType
  index?: number
  onClick: (marker: ManagePointType) => void
}

const iconsMarker = {
  active: 'Check',
  notActive: 'Minus',
  lead: 'Search',
  closed: 'Cross',
}

const typeOfVisits = {
  withoutVisit: {
    bg: 'bg-white',
    text: 'gray-9',
    icon: 'blue-gray-8',
    triangle: 'border-t-white',
  },
  ownVisit: {
    bg: 'bg-blue-8',
    text: 'white',
    icon: 'white',
    triangle: 'border-t-blue-8',
  },
  someoneElsesVisit: {
    bg: 'bg-blue-light',
    text: 'white',
    icon: 'white',
    triangle: 'border-t-blue-light',
  },
}

const BASE_FACTOR = 7.1
const ICON_WIDTH = 20
const INDEX_WIDTH = 20
const PADDING = 18

export const CustomMarker = ({ marker, index = 0, onClick }: CustomMarkerProps) => {
  const iconName = marker.groupAlias ? iconsMarker[marker.groupAlias] : ''
  const colorSchema = marker.visitOwnershipType ? typeOfVisits[marker.visitOwnershipType] : typeOfVisits.ownVisit

  const iconWidth = iconName ? ICON_WIDTH : 0
  const indexWidth = index ? INDEX_WIDTH : 0

  const calculateWidth = useMemo(() => {
    const markerWidth = iconWidth + indexWidth + PADDING + marker.title.length * BASE_FACTOR

    return markerWidth >= MAX_MARKER_WIDTH ? MAX_MARKER_WIDTH : markerWidth
  }, [marker.title.length, iconWidth, indexWidth])

  return (
    <OverlayView
      key={marker.id}
      position={marker.location}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      getPixelPositionOffset={(width) => getPixelPositionOffset(width)}
    >
      <div
        className={`relative flex gap-1 ${colorSchema.bg} p-1 pr-2 ${colorSchema.text} rounded shadow-level-1`}
        onClick={() => onClick(marker)}
        style={{ width: `${calculateWidth}px` }}
      >
        {orNull(
          index,
          <div
            className={cx(
              `flex h-5 flex-none items-center justify-center rounded-sm bg-white ${index > 99 ? 'min-w-5 px-0.5' : 'w-5'}`,
            )}
          >
            <Typography variant='caption' color='blue-8' className='font-semibold'>
              {index}
            </Typography>
          </div>,
        )}
        {orNull(
          iconName,
          <div>
            <Icon name={iconName} width={20} height={20} color={colorSchema.icon as TIconColors} />
          </div>,
        )}
        <Typography variant='caption' color={colorSchema.text as TTypographyColors} className='truncate'>
          {marker.title}
        </Typography>
        {orNull(
          marker.daysFromLastVisit,
          <div className='absolute right-0 top-0 flex h-5 min-w-[20px] -translate-y-1/2 translate-x-1/3 transform items-center justify-center rounded-full bg-white p-1 shadow-level-1'>
            <Typography variant='caption'>{marker.daysFromLastVisit}</Typography>
          </div>,
        )}
        <div
          className={`absolute -bottom-[6px] left-1/2 h-0 w-0 -translate-x-1/2 border-l-[6px] border-r-[6px] border-t-[6px] border-transparent ${colorSchema.triangle}`}
        />
      </div>
    </OverlayView>
  )
}
