import type { TFunction } from 'i18next'

import {
  CellText,
  CellLink,
  CellProgressBar,
  CellPhonesTitle,
  CellPhonesValue,
  CellCopy,
  CellTextEdit,
  CellLocation,
  CellActiveCoupon,
  CellCustomerAddress,
  CellStatus,
} from 'src/components/common'

import { CustomerType } from 'src/models'

import { createCustomerPhone } from 'src/utils'

type CustomerModelProps = {
  data: CustomerType | null
  loadingFetch: boolean
  visitId?: number
  t: TFunction
}

export const getCustomerModel = ({ data, loadingFetch, t, visitId }: CustomerModelProps) => {
  const copyValue = `${t('customers:customer_name')}: ${data?.title}\n${t('customers:customer_id')}: ${data?.awsId}`

  const AwsId = () =>
    CellCopy({
      value: data?.awsId,
      disabled: !data?.awsId,
      copiedText: 'customer_id_copied',
      copyValue,
      loadingFetch,
    })
  const Status = () => CellStatus({ value: data?.status || null, loadingFetch })
  const VatNumber = () => CellText({ value: data?.vat, loadingFetch })
  const ActiveCoupon = () => CellActiveCoupon(visitId)
  const Phone = () =>
    CellLink({
      value: createCustomerPhone({
        phonePrefix: data?.phonePrefix,
        phoneNumber: data?.phoneNumber,
      }),
      type: 'tel',
      loadingFetch,
    })
  const Email = () => CellLink({ value: data?.email, type: 'mailto', loadingFetch })
  const Note = () => CellTextEdit({ value: data?.notes, loadingFetch })
  const Address = () => CellCustomerAddress({ value: data?.address, loadingFetch })
  const RealLocation = () => CellLocation({ value: data?.realLocation, customerId: data?.id, loadingFetch })
  const Business = () => CellText({ value: data?.businessType?.label, loadingFetch })
  const Employees = () => CellText({ value: data?.quantityEmployees?.label, loadingFetch })
  const Loyalty = () => CellProgressBar({ value: data?.loyaltyProgram || null, loadingFetch })

  const contactPersons =
    data?.contactPersons.map((item) => {
      const Name = () => CellText({ value: item.name, loadingFetch })
      const PhonesTitle = () => CellPhonesTitle({ title: t('customers:phone'), value: item.phones })
      const PhonesValue = () => CellPhonesValue({ value: item.phones, loadingFetch })
      const Position = () => CellText({ value: item.position, loadingFetch })

      return {
        id: item.id,
        titleName: 'customers:contact_person',
        componentPerson: Name,
        phonesTitle: PhonesTitle,
        phonesValue: PhonesValue,
        positionTitle: 'customers:position',
        position: Position,
      }
    }) || []

  return {
    customerInfo: [
      {
        id: 1,
        title: 'customers:customer_id',
        component: AwsId,
      },
      {
        id: 2,
        title: 'customers:status',
        component: Status,
      },
      {
        id: 3,
        title: 'customers:loyalty_program',
        component: Loyalty,
      },
      {
        id: 4,
        title: 'customers:vat',
        component: VatNumber,
      },
      {
        id: 5,
        title: 'customers:active_coupon',
        component: ActiveCoupon,
      },
      {
        id: 6,
        title: 'customers:note',
        component: Note,
      },
      {
        id: 7,
        title: 'customers:real_location',
        component: RealLocation,
      },
      {
        id: 8,
        title: 'customers:business_type',
        component: Business,
      },
      {
        id: 9,
        title: 'customers:quantity_employees',
        component: Employees,
      },
      {
        id: 10,
        title: 'customers:phone',
        component: Phone,
      },
      {
        id: 11,
        title: 'customers:email',
        component: Email,
      },
      {
        id: 12,
        title: 'customers:address',
        component: Address,
      },
    ],
    contactPersons,
  }
}
