import { Typography } from 'antd'
import cx from 'clsx'
import { default as NextLink } from 'next/link'
import { LinkProps as LinkPropsNext } from 'next/link'
import { PropsWithChildren, CSSProperties } from 'react'
import { UrlObject } from 'url'

import { ternary } from 'src/utils'

type LinkProps = PropsWithChildren<
  LinkPropsNext & {
    style?: CSSProperties
    className?: string
    blank?: boolean
    href: string | UrlObject
  }
>

export const Link = ({
  children,
  style,
  className = '',
  shallow = true,
  passHref = true,
  blank = false,
  href,
  ...rest
}: LinkProps) => {
  const linkStyles = cx(className)

  return ternary(
    blank,
    <Typography.Link className={linkStyles} href={href as string} target='_blank' rel='noopener noreferrer'>
      {children}
    </Typography.Link>,
    <NextLink shallow={shallow} passHref={passHref} href={href} {...rest}>
      <Typography.Link style={style} className={linkStyles}>
        {children}
      </Typography.Link>
    </NextLink>,
  )
}
