import { zodResolver } from '@hookform/resolvers/zod'
import { useTranslation } from 'next-i18next'
import { isNil } from 'ramda'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import {
  Button,
  Form,
  FormControl,
  FormField,
  FormFields,
  FormItem,
  FormMessage,
  Separator,
  Typography,
} from 'src/components/ui'

import { FormSubmitFn, StaticDataItemType, FormCreateCompanyEnum, FormCreateCustomerType } from 'src/models'

import { useAuthProfile } from 'src/bus/auth'
import { formShapes } from 'src/bus/forms'

import { messages } from 'src/utils'

type CreateCustomerFormProps = {
  businessType: StaticDataItemType[]
  quantityEmployees: StaticDataItemType[]
  zonesOptions: StaticDataItemType[]
  sectorsOptions: StaticDataItemType[]
  loadingCreate?: boolean
  loadingFetchStaticData?: boolean
  loadingZonesFetch?: boolean
  loadingSectorsFetch?: boolean
  onSubmit?: FormSubmitFn<FormCreateCustomerType>
  onSelectZoneId: (id: string) => void
}

export const CreateCustomerForm = ({
  businessType,
  quantityEmployees,
  zonesOptions,
  sectorsOptions,
  loadingCreate = false,
  loadingFetchStaticData = false,
  loadingZonesFetch = false,
  loadingSectorsFetch = false,
  onSubmit,
  onSelectZoneId,
}: CreateCustomerFormProps) => {
  const { t } = useTranslation()
  const { country } = useAuthProfile()

  const formProps = useForm<FormCreateCustomerType>({
    defaultValues: { ...formShapes.createCustomer.shape },
    resolver: zodResolver(formShapes.createCustomer.schema({ t, country })),
  })

  const { control, handleSubmit, setError, reset, watch, setValue } = formProps

  const onSubmitForm = handleSubmit(async (values) => {
    await onSubmit?.({ values, acts: { setError, reset } })
  })

  const watchedFields = watch()

  useEffect(() => {
    if (isNil(watchedFields.zone)) {
      onSelectZoneId('')
      setValue('sector', null)
    } else {
      onSelectZoneId(String(watchedFields.zone.id))
      setValue('sector', null)
    }
  }, [onSelectZoneId, watchedFields.zone, setValue])

  return (
    <Form {...formProps}>
      <FormFields.FormGeneral
        onSubmit={onSubmitForm}
        scroll
        footer={
          <>
            <Separator className='my-2' />
            <div className='px-2'>
              <Button block type='submit' loading={loadingCreate} disabled={loadingFetchStaticData}>
                {t('buttons:save')}
              </Button>
            </div>
          </>
        }
      >
        <div className='mb-4'>
          <Typography className='font-semibold'>{t('buttons:customer_info')}</Typography>
        </div>
        <FormField
          control={control}
          name={FormCreateCompanyEnum.title}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <FormFields.InputBase
                  {...field}
                  placeholder={t(messages.createCustomer.title.placeholder)}
                  hasDisabled={loadingCreate}
                />
              </FormControl>
              <FormMessage className='absolute' />
            </FormItem>
          )}
        />
        <div className='flex items-stretch justify-between gap-2'>
          <div className='basis-8/12'>
            <FormField
              control={control}
              name={FormCreateCompanyEnum.city}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <FormFields.InputBase
                      {...field}
                      placeholder={t(messages.createCustomer.city.placeholder)}
                      hasDisabled={loadingCreate}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className='basis-5/12'>
            <FormField
              control={control}
              name={FormCreateCompanyEnum.index}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <FormFields.InputBase
                      {...field}
                      placeholder={t(messages.createCustomer.index.placeholder)}
                      hasDisabled={loadingCreate}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>
        <div className='flex items-stretch justify-between gap-2'>
          <div className='basis-8/12'>
            <FormField
              control={control}
              name={FormCreateCompanyEnum.street}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <FormFields.InputBase
                      {...field}
                      placeholder={t(messages.createCustomer.street.placeholder)}
                      hasDisabled={loadingCreate}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className='basis-5/12'>
            <FormField
              control={control}
              name={FormCreateCompanyEnum.house}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <FormFields.InputBase
                      {...field}
                      placeholder={t(messages.createCustomer.houseNumber.placeholder)}
                      hasDisabled={loadingCreate}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>
        <FormField
          control={control}
          name={FormCreateCompanyEnum.notes}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <FormFields.InputBase
                  {...field}
                  placeholder={t(messages.createCustomer.notes.placeholder)}
                  hasDisabled={loadingCreate}
                />
              </FormControl>
              <FormMessage className='absolute' />
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name={FormCreateCompanyEnum.businessType}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <FormFields.InputSelect
                  {...field}
                  placeholder={t(messages.createCustomer.businessType.placeholder)}
                  options={businessType}
                  loading={loadingFetchStaticData}
                  hasDisabled={loadingCreate}
                />
              </FormControl>
              <FormMessage className='absolute' />
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name={FormCreateCompanyEnum.quantityEmployees}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <FormFields.InputSelect
                  {...field}
                  placeholder={t(messages.createCustomer.quantityEmployees.placeholder)}
                  options={quantityEmployees}
                  loading={loadingFetchStaticData}
                  hasDisabled={loadingCreate}
                />
              </FormControl>
              <FormMessage className='absolute' />
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name={FormCreateCompanyEnum.zone}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <FormFields.InputSelect
                  {...field}
                  placeholder={t(messages.createCustomer.zone.placeholder)}
                  options={zonesOptions}
                  loading={loadingZonesFetch}
                  hasDisabled={loadingCreate}
                />
              </FormControl>
              <FormMessage className='absolute' />
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name={FormCreateCompanyEnum.sector}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <FormFields.InputSelect
                  {...field}
                  placeholder={t(messages.createCustomer.sector.placeholder)}
                  options={sectorsOptions}
                  loading={loadingSectorsFetch}
                  hasDisabled={loadingCreate}
                />
              </FormControl>
              <FormMessage className='absolute' />
            </FormItem>
          )}
        />
      </FormFields.FormGeneral>
    </Form>
  )
}
