import { Checkbox } from 'antd'
import { CheckboxGroupProps } from 'antd/es/checkbox'
import cx from 'clsx'
import { forwardRef, PropsWithChildren } from 'react'

import st from './Styles.module.css'

export type InputCheckboxGroupType = PropsWithChildren<
  CheckboxGroupProps & {
    hasDisabled?: boolean
  }
>

export const InputCheckboxGroup = forwardRef<HTMLInputElement, InputCheckboxGroupType>(
  ({ className, hasDisabled = false, children, ...rest }, ref) => {
    const checkboxClasses = cx(
      st['checkbox'],
      {
        [st['disabled']]: hasDisabled,
      },
      className,
    )

    return (
      <Checkbox.Group {...rest} className={checkboxClasses} disabled={hasDisabled} ref={ref}>
        {children}
      </Checkbox.Group>
    )
  },
)

InputCheckboxGroup.displayName = 'InputCheckboxGroup'
