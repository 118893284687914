import { Radio } from 'antd'
import { RadioGroupProps } from 'antd'
import cx from 'clsx'
import { forwardRef, PropsWithChildren } from 'react'

import st from './Styles.module.css'

export type InputRadioGroupType = PropsWithChildren<
  RadioGroupProps & {
    hasDisabled?: boolean
  }
>

export const InputRadioGroup = forwardRef<HTMLInputElement, InputRadioGroupType>(
  ({ className = '', hasDisabled = false, children, ...rest }, ref) => {
    const radioClassNames = cx(
      st['radio'],
      {
        [st['disabled']]: hasDisabled,
      },
      className,
    )

    return (
      <Radio.Group {...rest} className={radioClassNames} disabled={hasDisabled} ref={ref}>
        {children}
      </Radio.Group>
    )
  },
)

InputRadioGroup.displayName = 'InputRadioGroup'
