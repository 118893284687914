import { Input } from 'antd-mobile'
import { InputProps, InputRef } from 'antd-mobile/es/components/input'
import cx from 'clsx'
import { forwardRef } from 'react'

import st from './Styles.module.css'

type InputPasswordType = InputProps & {
  children?: never
  size?: 'small' | 'middle' | 'large'
  hasDisabled?: boolean
}

export const InputPassword = forwardRef<InputRef, InputPasswordType>((props, ref) => {
  const { className, hasDisabled = false, size = 'large', maxLength = 100, placeholder = 'Password', ...rest } = props

  const inputStyle = cx(
    st['input-password'],
    {
      [st.disabled]: hasDisabled,
      [st[`input-password-size-${size}`]]: !!size,
    },
    className,
  )

  return (
    <Input
      {...rest}
      type='password'
      autoComplete='on'
      className={inputStyle}
      disabled={hasDisabled}
      placeholder={placeholder}
      maxLength={maxLength}
      ref={ref}
    />
  )
})

InputPassword.displayName = 'InputPassword'
