import { book } from 'src/init/book'

import { WrapperIcon } from 'src/components/common/WrapperIcon'
import type { IconProps } from 'src/components/ui/Icon'

import type { TabBarType } from 'src/models'

type TNavIcon = IconProps & {
  active?: boolean
  activeName?: string
  inactiveName?: string
}

export const bottomNavigationTabs = (isDisableParcels: boolean): TabBarType[] => {
  const NavIcon = (props: TNavIcon) =>
    WrapperIcon({
      ...props,
      width: 32,
      height: 32,
      color: 'gray-5',
      className: 'group-focus:fill-text group-hover:fill-text group-active:fill-text',
    })

  const HistoryIcon = ({ active, activeName, inactiveName }: TNavIcon) =>
    NavIcon({
      activeName,
      inactiveName,
      active,
    })

  const ParcelsIcon = ({ active, activeName, inactiveName }: TNavIcon) =>
    NavIcon({
      activeName,
      inactiveName,
      active,
    })

  const DashboardIcon = ({ active, activeName, inactiveName }: TNavIcon) =>
    NavIcon({
      activeName,
      inactiveName,
      active,
    })

  const CustomersIcon = ({ active, activeName, inactiveName }: TNavIcon) =>
    NavIcon({
      activeName,
      inactiveName,
      active,
    })

  const ProfileIcon = ({ active, activeName, inactiveName }: TNavIcon) =>
    NavIcon({
      activeName,
      inactiveName,
      active,
    })

  return [
    {
      id: '/visits/history',
      key: book.visitsHistory,
      icon: (active) =>
        HistoryIcon({
          activeName: 'VisitsFill',
          inactiveName: 'Visits',
          active,
        }),
    },
    {
      id: '/parcels',
      key: book.parcelsClear,
      disabled: isDisableParcels,
      icon: (active) =>
        ParcelsIcon({
          activeName: 'BoxFill',
          inactiveName: 'Box',
          active,
        }),
    },
    {
      id: '/dashboard',
      key: book.dashboard,
      icon: (active) =>
        DashboardIcon({
          activeName: 'NavigationFill',
          inactiveName: 'Navigation',
          active,
        }),
    },
    {
      id: '/customers',
      key: book.customers,
      icon: (active) =>
        CustomersIcon({
          activeName: 'GarageFill',
          inactiveName: 'Garage',
          active,
        }),
    },
    {
      id: '/profile',
      key: book.profile,
      icon: (active) =>
        ProfileIcon({
          activeName: 'UserFill',
          inactiveName: 'User',
          active,
        }),
    },
  ]
}
