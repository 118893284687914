import { PrintQRCode } from 'src/components/common'
import { DialogContent, DialogHeader, Loader } from 'src/components/ui'

import { useParcelsDetailsFetch, useParcelsQRCodePrint } from 'src/bus/parcels'

import { GET_ENUMS, orNull, ternary } from 'src/utils'

export const DialogShowQRCode = ({ id }: { id?: number }) => {
  const { parcelsDetails, loadingParcelsDetailsFetch } = useParcelsDetailsFetch(id)
  const { parcelsQRCode, loadingFetchQRCode } = useParcelsQRCodePrint(parcelsDetails)

  return (
    <DialogContent name={GET_ENUMS.dialog.showQRCode}>
      {ternary(
        loadingFetchQRCode || loadingParcelsDetailsFetch,
        <DialogHeader>
          <Loader type='relative' />
        </DialogHeader>,
        <>{orNull(parcelsQRCode?.content, <PrintQRCode value={parcelsQRCode?.content} />)}</>,
      )}
    </DialogContent>
  )
}
