import { Checkbox } from 'antd'
import { CheckboxProps } from 'antd'
import cx from 'clsx'
import { CheckboxRef } from 'rc-checkbox/lib'
import { forwardRef, PropsWithChildren } from 'react'

import st from './Styles.module.css'

export type InputCheckboxType = PropsWithChildren<
  CheckboxProps & {
    className?: string
    hasDisabled?: boolean
  }
>

export const InputCheckbox = forwardRef<CheckboxRef, InputCheckboxType>(
  ({ className = '', value = false, children, hasDisabled = false, ...rest }, ref) => {
    const checkboxClasses = cx(st.checkbox, { [st.disabled]: hasDisabled }, className)

    return (
      <Checkbox {...rest} className={checkboxClasses} disabled={hasDisabled} value={value} ref={ref}>
        {children}
      </Checkbox>
    )
  },
)

InputCheckbox.displayName = 'InputCheckbox'
