import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { book } from 'src/init/book'

import { StatusItem, WrappedNodeDefault } from 'src/components/common'
import { SwipeAction, ListItem, Typography } from 'src/components/ui'

import { CustomerType } from 'src/models'

import { getCustomerItemActions, getClosedStatus } from 'src/utils'

type CustomersListItemProps = {
  item: CustomerType
  onClick?: () => void
}

export const CustomersListItem = ({ item, onClick }: CustomersListItemProps) => {
  const { t } = useTranslation()
  const router = useRouter()

  const onSelectCell = (pathname: string) => async () => {
    await router.push({ pathname, query: { customerId: item.id } })
  }

  const hasClosed = getClosedStatus(item)

  const customerActions = getCustomerItemActions({ onSelectCell, t, hasClosed })

  return (
    <SwipeAction rightActions={customerActions} className='rounded-md [&:not(:last-child)]:mb-2'>
      <ListItem size='lg' onClick={onClick}>
        <Link href={`${book.customers}/${item.id}`}>
          <div className='flex items-center gap-2'>
            <div className='grid basis-5/12'>
              <Typography className='font-semibold'>{WrappedNodeDefault(item.title)}</Typography>
              <Typography variant='caption' color='gray-7' className='truncate'>
                {item?.companyTitle}
              </Typography>
            </div>
            <div className='grid basis-2/12 justify-center'>
              <Typography>{WrappedNodeDefault(item.daysFromLastVisit)}</Typography>
            </div>
            <div className='flex basis-2.5/12 justify-center'>
              <StatusItem status={item.status} />
            </div>
            <div className='grid basis-2.5/12 justify-center'>
              <Typography>{WrappedNodeDefault(item.sector)}</Typography>
            </div>
          </div>
        </Link>
      </ListItem>
    </SwipeAction>
  )
}
