import { useRouter } from 'next/router'

import { Button } from 'src/components/ui'

import { useAuthProfile } from 'src/bus/auth'

import { bottomNavigationTabs, cn } from 'src/utils'

export const BottomNavigationBar = () => {
  const router = useRouter()
  const { isCountryDE, isCountryAT } = useAuthProfile()

  const isDisableParcels = isCountryDE || isCountryAT

  const onChange = (key: string) => router.pathname !== key && router.push(key)

  const getActiveKey = (id: string) => {
    if (router.pathname.includes(id) && id !== '/') {
      return router.pathname.includes(id)
    }

    return router.pathname === id && id === '/'
  }

  return (
    <div className='relative z-[900] flex w-full items-center justify-between bg-text px-3 py-2'>
      {bottomNavigationTabs(isDisableParcels).map((item) => {
        const isActive = getActiveKey(item.id)

        return (
          <Button
            variant='navbar'
            size='icon'
            key={item.key}
            onClick={() => onChange(item.key)}
            disabled={item.disabled}
            className={cn('group', { 'pointer-events-none': router.pathname === item.key })}
          >
            {item.icon(isActive)}
          </Button>
        )
      })}
    </div>
  )
}
