import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import QS from 'qs'

import { book } from 'src/init/book'

import { TotalItem, TotalItemLabel, TotalItemValue } from 'src/components/common'
import { Button } from 'src/components/ui'

import { ClaimStatusEnum } from 'src/models'

import { useClaimsListFetch } from 'src/bus/claims'
import { useReturnsTotalFetch } from 'src/bus/returns'

import { getHasData, getTranslate, orNull } from 'src/utils'

type ReturnTotalProps = {
  showConfirm?: boolean
}

export const ReturnsTotal = ({ showConfirm = false }: ReturnTotalProps) => {
  const { t } = useTranslation()
  const router = useRouter()
  const { returnsTotal, loadingReturnsTotalFetch } = useReturnsTotalFetch()
  const { claimsList, loadingClaimsListFetch } = useClaimsListFetch()

  const hasDisabled = loadingReturnsTotalFetch || loadingClaimsListFetch
  const hasConfirmBtn =
    showConfirm && getHasData(claimsList.items) && returnsTotal?.isClaimsReady && returnsTotal?.isEditable

  const { id, customerId } = router.query

  const onReturnConfirm = async () => {
    await router.push({
      pathname: `${book.returns}/${id}${book.returnDetailsConfirm}`,
      query: QS.stringify({
        status: ClaimStatusEnum.confirmed,
        claimStatus: ClaimStatusEnum.confirmed,
        customerId,
      }),
    })
  }

  const getReturnsT = getTranslate('returns:returns_details', t)

  return (
    <>
      <div className='grid grid-cols-[1fr_1fr_2fr] gap-2'>
        <TotalItem label={<TotalItemLabel>{getReturnsT('total_qty')}</TotalItemLabel>}>
          <TotalItemValue loadingFetch={loadingReturnsTotalFetch} precision={0} isNumber>
            {returnsTotal?.total?.quantity}
          </TotalItemValue>
        </TotalItem>
        <TotalItem label={<TotalItemLabel>{getReturnsT('total_article')}</TotalItemLabel>}>
          <TotalItemValue loadingFetch={loadingReturnsTotalFetch} precision={0} isNumber>
            {returnsTotal?.total?.articles}
          </TotalItemValue>
        </TotalItem>
        <TotalItem variant='amount' label={<TotalItemLabel>{getReturnsT('total_sum')}</TotalItemLabel>}>
          <TotalItemValue loadingFetch={loadingReturnsTotalFetch}>{returnsTotal?.total?.sum}</TotalItemValue>
        </TotalItem>
      </div>
      {orNull(
        hasConfirmBtn,
        <div className='mt-2'>
          <Button block disabled={hasDisabled} onClick={onReturnConfirm}>
            {t('buttons:confirm_return')}
          </Button>
        </div>,
      )}
    </>
  )
}
