import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { defaultTo } from 'ramda'
import { ReactNode } from 'react'

import { book } from 'src/init/book'

import { CellCustomerAddress, CellProgressBar, CellStatus, CellText } from 'src/components/common'
import { Button, DialogContent, DialogHeader, Separator, Skeleton, Typography } from 'src/components/ui'

import { useCustomersDetailsFetch } from 'src/bus/customers'

import { useDialog } from 'src/contexts'

import { formatDate, GET_ENUMS, getTranslate, orNull, ternary } from 'src/utils'

type DialogCustomerCardProps = {
  id?: number
  visitId?: number
  onOpenOtherPopup?: (key: string) => void
}

export const DialogCustomerCard = ({ id, visitId, onOpenOtherPopup }: DialogCustomerCardProps) => {
  const router = useRouter()
  const { t } = useTranslation()
  const { setOnPageDialog } = useDialog()
  const { customersDetails, loadingCustomersDetailsFetch } = useCustomersDetailsFetch(id)

  const getCustomerCardT = getTranslate('common:modal_customer_card', t)

  const employeeAndRole = `${defaultTo(customersDetails?.nextPlanedVisit?.employee, '')} ${defaultTo(customersDetails?.nextPlanedVisit?.employeeRole, '')}`

  const renderRow = (label: string, component: ReactNode) => {
    return (
      <div className='flex items-center justify-between gap-2'>
        <div className='basis-5/12'>
          <Typography variant='secondary' color='gray-7'>
            {getCustomerCardT(label)}
          </Typography>
        </div>
        <div className='basis-7/12'>{component}</div>
      </div>
    )
  }

  const onGoToCustomerInfo = async () => {
    setOnPageDialog(GET_ENUMS.dialog.customerCard, false)
    await router.push(`${book.customers}/${id}`)
  }

  const onGoToCreateVisit = async () => {
    setOnPageDialog(GET_ENUMS.dialog.customerCard, false)
    await router.push({ pathname: book.visitsCreate, query: { customerId: id } })
  }

  return (
    <DialogContent name={GET_ENUMS.dialog.customerCard} className='bg-gray-1 px-0'>
      <DialogHeader className='mb-3 px-4'>
        {ternary(
          loadingCustomersDetailsFetch,
          <Skeleton className='h-6 w-full' />,
          <>
            <Typography variant='title'>{customersDetails?.title}</Typography>
            {orNull(
              customersDetails?.companyTitle,
              <Typography variant='secondary' color='gray-7' className='truncate'>
                {customersDetails?.companyTitle}
              </Typography>,
            )}
          </>,
        )}
      </DialogHeader>
      <div className='bg-gray-1 px-4'>
        {renderRow(
          'status',
          <CellStatus value={customersDetails?.status} loadingFetch={loadingCustomersDetailsFetch} />,
        )}
      </div>
      <div className='bg-gray-1 px-4'>
        <Separator className='mb-3' />
        {renderRow(
          'loyalty_program',
          <CellProgressBar
            value={customersDetails?.loyaltyProgram || null}
            loadingFetch={loadingCustomersDetailsFetch}
          />,
        )}
      </div>
      <div className='bg-gray-1 px-4'>
        <Separator className='mb-3' />
        {renderRow(
          'address',
          <CellCustomerAddress value={customersDetails?.address} loadingFetch={loadingCustomersDetailsFetch} />,
        )}
      </div>
      <div className='bg-gray-1 px-4'>
        <Separator className='mb-3' />
        {renderRow(
          'profile',
          <CellText
            value={getCustomerCardT(customersDetails?.awsId ? 'yes' : 'no')}
            loadingFetch={loadingCustomersDetailsFetch}
          />,
        )}
      </div>
      <div className='bg-white'>
        <Separator />
        <div className='p-4'>
          <div className='py-3'>
            {renderRow(
              'next_planned_visit',
              <CellText
                value={formatDate(customersDetails?.nextPlanedVisit?.date, 'slash')}
                loadingFetch={loadingCustomersDetailsFetch}
              />,
            )}
          </div>
          <Separator className='mb-3' />
          <div className='py-3'>
            {renderRow('employee', <CellText value={employeeAndRole} loadingFetch={loadingCustomersDetailsFetch} />)}
          </div>
          <Separator className='mb-2' />
          {visitId ? (
            <div className='grid grid-cols-2 gap-2'>
              <Button
                variant='outline'
                block
                onClick={() => onOpenOtherPopup?.(GET_ENUMS.dialog.replanVisit)}
                disabled={loadingCustomersDetailsFetch}
              >
                {getCustomerCardT('reschedule')}
              </Button>
              <Button
                block
                onClick={() => onOpenOtherPopup?.(GET_ENUMS.dialog.cancelVisit)}
                disabled={loadingCustomersDetailsFetch}
              >
                {getCustomerCardT('cancel_visit')}
              </Button>
            </div>
          ) : (
            <Button block onClick={onGoToCreateVisit} disabled={loadingCustomersDetailsFetch}>
              {t('buttons:create_new_visit')}
            </Button>
          )}
        </div>
      </div>
      <div className='px-4'>
        <Button variant='ghost' block onClick={onGoToCustomerInfo}>
          {getCustomerCardT('view_full_customer_info')}
        </Button>
      </div>
    </DialogContent>
  )
}
