import { Gutter } from 'antd/es/grid/row'
import { IStringifyOptions } from 'qs'
import { z } from 'zod'

import { PlanForTodayMetricIcon } from 'src/models'

import { verifyEnvironment } from 'src/utils/verify-environment'

export const rowGutters: [Gutter, Gutter] = [8, 0]

export const googleMapLibraries = ['places', 'geometry', 'marker']

export const initialLocale = 'fr'
export const initialCountry = 'fr'
export const initialNavigation = 'google'

export const CAUSE_COMMERCIAL = 'C'
export const REASON_DIDNT_REACH = 'cc_didnt_reach_client'

export const FAR_AWAY_DISTANCE = 500 // meters

export const TOAST_DURATION = 4000

export const MAX_MARKER_WIDTH = 144

export const AUTH_COOKIE = 'atd-pro-crm-auth-token'
export const COOKIE_MAX_AGE = 30 * 24 * 60 * 60 // 30 days

export const REGEXP_UPPER_LETTER = /\.?(?=[A-Z])/
export const REGEXP_INTEGER = /^[0-9\b]+$/
export const REGEXP_PWD_SPACE = /^\S$|^\S[ \S]*\S$/
export const REGEXP_VAT = /^[0-9a-zA-Z\-–—]+$/
export const REGEXP_SIGN_FORMAT =
  /^data:image\/(?:gif|png|jpeg|bmp|webp|svg\+xml)(?:;charset=utf-8)?;base64,(?:[A-Za-z0-9]|[+/])+={0,2}/

export const AVAILABLE_PROFILES_ID = [
  '13457',
  '13454',
  '13428',
  '13426',
  '13429',
  '13442',
  '13443',
  '13444',
  '13431',
  '13439',
  '13460',
  '13472',
  '13433',
  '13446',
  '13481',
  '13449',
  '13455',

  // for tests
  '16339',
  '5',
  '13469',
  '17810',
  '8',
  '13508',
  '16341',
  '6',
  '13470',
]

export const usefulLinks = {
  vat: 'https://ec.europa.eu/taxation_customs/vies/#/vat-validation',
  mail: (subject: string, body: string) =>
    'mailto:pro_complaints@autodoc.eu?subject=' + encodeURIComponent(subject) + '&body=' + encodeURIComponent(body),
  iban: 'https://www.ibancalculator.com/iban_validieren.html',
  ibanNL: 'https://nl.iban.com/',
  google: (date: string, name: string) =>
    `https://docs.google.com/forms/d/e/1FAIpQLSdvodqFnIOgS8iCs0lQME10h-PguCHNvhWtgHhNVqIk_BiXoQ/viewform?usp=pp_url&entry.1196248976=${date}&entry.1596813414=${name}`,
  googleFR: (date: Date, name: string) =>
    `https://docs.google.com/forms/d/1LDb0_GCNRY1N1wumkdANGzM8jSWYSFAZV9Kr_nCvFwI/edit?ts=667abaa8&entry.457507247_year=${date.getFullYear()}&entry.457507247_month=${date.getMonth()}&entry.457507247_day=${date.getDay()}&entry.1984510510=${name}`,
  ups: 'tel:0170200066#9061',
}

export const PROJECT_VISIT_NPS = 'atd-pro-crm-project-visit-nps'

export const googleMapNavigation = 'https://maps.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic'
export const wazeMapNavigation = 'https://maps.waze.com/ul?navigate=yes'

export const googleSearch = 'https://www.google.com/search?q='

export const otherVisitCancelReason = {
  id: 1,
  value: 'other',
  label: 'Other reason',
}

export const QS_QUERY_CONFIG = verifyEnvironment().isDevelopment ? { encode: false } : { encode: true }
export const ENCODE_OPTIONS: IStringifyOptions = { arrayFormat: 'brackets', ...QS_QUERY_CONFIG }

export const NPS_ID = 999999

export const MAX_WAYPOINTS_CHUNK = 25

export const NPS_IDS_EXCLUDED = [
  19087, 19086, 19083, 19081, 19082, 19085, 19084, 19080, 19231, 19225, 19199, 19233, 19232, 19234, 19385, 19410, 19409,
  19384, 19293, 19288,
]

export const NEW_PARCEL_ID = 999

export const DATE_FORMAT_TYPES = {
  dots: 'dd.MM.yyyy',
  slash: 'DD/MM/YYYY',
  slashes: 'dd/MM/yyyy',
  timeFull: 'HH:mm:ss',
  dashReversed: 'YYYY-MM-DD',
  dashReversedTime: 'YYYY-MM-DD HH:mm:ss',
  slashShort: 'DD/MM/YY',

  dot: 'DD.MM.YYYY',
  dash: 'DD-MM-YYYY',
  dotShort: 'DD.MM.YY',
  dashShort: 'DD-MM-YY',
  dashReversedShort: 'YY-MM-DD',

  dotTime: 'DD.MM.YYYY, HH:mm:ss',
  slashTime: 'DD/MM/YY HH:mm:ss',
}

export const OPTION_OBJECT = {
  id: z.number(),
  label: z.string().trim(),
  value: z.string().trim(),
}

export const googleMapDefaultOptions = {
  center: {
    lat: 48.8612779,
    lng: 2.3157325,
  },
  zoom: 11,
  options: {
    streetViewControl: false,
    mapTypeControl: false,
    zoomControl: false,
    fullscreenControl: false,
    mapId: '6e120bcd575d29f7',
  },
}

export const TOUR_SUCCESS_VALUE = 100

export const FLOATING_PANEL_ANCHORS = {
  ONE_STEP: [140, 196],
  TWO_STEPS: [140, 242],
  THREE_STEPS_WITHOUT_FOOTER: [84, 234],
  THREE_STEPS: [140, 289],
  FIVE_STEPS: [140, 384],
  DEFAULT: [140, 341],
}

export const GET_ENUMS = {
  drawer: {
    language: 'language',
    navigation: 'navigation',
    usefulLinks: 'usefulLinks',
    filters: 'filters',
    confirmList: 'confirmList',
  },
  dialog: {
    stopTour: 'stop-tour',
    createCoupon: 'create-coupon',
    cancelVisit: 'cancel-visit',
    replanVisit: 'replan-visit',
    customerCredentials: 'customer-credentials',
    customerIssue: 'customer-issue',
    editNote: 'edit-note',
    noVisits: 'no-visits',
    noPoints: 'no-points',
    vatInformation: 'vat-information',
    cancelVisitReason: 'cancel-visit-reason',
    info: 'info',
    returnsInfoMarkers: 'returns-info-markers',
    deleteArticle: 'delete-article',
    moveToArticle: 'move-to-article',
    moveOutArticle: 'move-out-article',
    recoverArticle: 'recover-article',
    replaceArticle: 'replace-article',
    showQRCode: 'show-qr-code',
    farAwayReport: 'far-away-report',
    trackNumberNL: 'track-number-nl',
    customerCard: 'customer-card',
  },
  alertDialog: {
    confirm: 'confirm',
    closeCustomer: 'close-customer',
    openCustomer: 'open-customer',
    leavePage: 'leave-page',
    packParcel: 'pack-parcel',
    shipmentParcel: 'shipment-parcel',
    sendRequest: 'send-request',
    rejectParcel: 'reject-parcel',
    unpackParcel: 'unpack-parcel',
  },
}

export const CLIENT_API_INITIAL_ERR = {
  status: 500,
  error: {
    type: 'ServerErrorCustom',
    message: "Response from server doesn't have field message or status",
  },
}

export const DBR_LICENCE_ERROR =
  'Failed to connect to Dynamsoft License Server: network connection error. Check your Internet connection or contact Dynamsoft Support (support@dynamsoft.com) to acquire an offline license.'

export const STATUS_TYPES = [
  'in-progress',
  'in_progress',
  'completed',
  'confirmed',
  'refused',
  'plat',
  'gold',
  'stnd',
  'lead',
  'prsp',
  'silv',
  'clos',
  'clsd',
  'nact',
  'ncst',
  'ncst_0',
  'ncst_1',
  'ncst_2',
  'ncst_3',
  'ncst_4',
]

export const METRIC_ICONS: PlanForTodayMetricIcon = {
  visits: 'User',
  returns: 'BoxReturn',
  loyalty: 'TrendingUp',
  conquest: 'CoinEuro',
  prospect: 'Search',
  conqProsp: 'CoinEuro',
}
