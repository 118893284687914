import cx from 'clsx'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { includes } from 'ramda'

import { Typography } from 'src/components/ui'

import { HeaderTabsType } from 'src/models'

import st from './Styles.module.css'

type HeaderTabsProps = {
  tabs: HeaderTabsType
  activePath: string
}

export const HeaderTabs = ({ tabs, activePath }: HeaderTabsProps) => {
  const { t } = useTranslation()

  return (
    <div className={st['container']}>
      <div className={st['tabs']}>
        {tabs.map((item) => {
          const itemClassName = cx(st['tabs-item'], {
            [st['tabs-item-active']]: includes(activePath, item.title),
          })

          return (
            <div className={itemClassName} key={item.title}>
              <Link replace href={item.key}>
                <Typography color='blue-gray-9' className='font-semibold'>
                  {t(item.title)}
                </Typography>
              </Link>
            </div>
          )
        })}
      </div>
    </div>
  )
}
