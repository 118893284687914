import { Col, Row } from 'antd'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

import { OrdersArticlesItem, SkeletonList } from 'src/components/common'
import { Empty, InfiniteScroll, Typography } from 'src/components/ui'

import { useOrdersArticlesFetch } from 'src/bus/orders'

import { getInfiniteListLength, rowGutters } from 'src/utils'

const articlesHeader = [
  { title: 'orders:list.article', span: 6 },
  { title: 'orders:list.article_type', span: 6 },
  { title: 'orders:list.brand_article', span: 5 },
  { title: 'orders:list.qty', span: 3 },
  { title: 'orders:list.sum', span: 4 },
]

export const OrdersArticles = () => {
  const { t } = useTranslation()
  const router = useRouter()

  const orderId = Number(router.query.id)
  const customerId = Number(router.query.customerId)

  const params = { orderId, customerId }

  const {
    ordersArticlesList,
    ordersArticlesListHasMore,
    onFetchMoreOrdersArticlesList,
    loadingOrdersArticlesListFetch,
  } = useOrdersArticlesFetch(params)

  const renderItems = () =>
    ordersArticlesList.pages.map((page) => {
      return page.items.map((item) => <OrdersArticlesItem key={item.id} item={item} />)
    })

  return (
    <>
      <div className='mb-3 px-2'>
        <Typography variant='secondary' className='font-semibold'>
          {t('articles_data')}
        </Typography>
      </div>
      <div className='bg-gray-1 p-2'>
        <Row gutter={rowGutters}>
          {articlesHeader.map((item) => {
            return (
              <Col key={item.title} span={item.span}>
                <Typography variant='caption' className='font-semibold'>
                  <span dangerouslySetInnerHTML={{ __html: t(item.title) }} />
                </Typography>
              </Col>
            )
          })}
        </Row>
      </div>
      <InfiniteScroll
        hasMore={ordersArticlesListHasMore}
        loadingFetch={loadingOrdersArticlesListFetch}
        onFetchMore={onFetchMoreOrdersArticlesList}
        dataLength={getInfiniteListLength(ordersArticlesList.pages)}
        loader={<SkeletonList type='orders-articles' />}
        scrollableTarget='orders-articles'
        renderItems={renderItems}
        empty={<Empty title='common:empty.no_activity' description='common:empty.orders_articles' />}
      />
    </>
  )
}
