import QRCode from 'react-qr-code'

type PrintQRCodeProps = {
  value?: string
  size?: number
  viewBox?: string
}

export const PrintQRCode = ({ value = '', size = 256, viewBox = '0 0 256 256' }: PrintQRCodeProps) => {
  return <QRCode size={size} className='h-auto w-full max-w-full' value={value} viewBox={viewBox} />
}
