import { Skeleton, Typography } from 'src/components/ui'

import { orNull } from 'src/utils'

type HeaderTitleProps = {
  title?: string
  subTitle?: string | null
  loadingFetch?: boolean
}

export const HeaderTitle = ({ title = '', subTitle = '', loadingFetch = false }: HeaderTitleProps) => {
  if (loadingFetch) {
    return <Skeleton className='h-6 w-full' />
  }

  return (
    <div className='flex flex-col overflow-hidden text-center'>
      <Typography className='truncate font-semibold'>{title}</Typography>
      {orNull(
        subTitle,
        <Typography variant='caption' color='gray-7' className='truncate'>
          {subTitle}
        </Typography>,
      )}
    </div>
  )
}
