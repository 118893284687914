import { z } from 'zod'

import { hasID } from './Base'
import { HttpRequestParams, selectSchema } from './index'

export const authLoginSchema = z.object({
  login: z.string(),
  password: z.string(),
})

export const authTokenSchema = z.object({
  token: z.string().min(1),
})

export const authNameSchema = z.enum(['service-agent', 'prospector'])

export const authRoleSchema = z.object({
  name: authNameSchema,
  label: z.string(),
})

export const authProfileBaseSchema = z.object({
  login: z.string(),
  email: z.string(),
  name: z.string(),
  language: selectSchema,
  country: selectSchema,
  role: authRoleSchema,
})

export const authProfileSchema = authProfileBaseSchema.merge(hasID)

export type AuthLoginType = z.infer<typeof authLoginSchema>
export type AuthTokenType = z.infer<typeof authTokenSchema>
export type AuthProfileType = z.infer<typeof authProfileSchema>
export type AuthRole = z.infer<typeof authNameSchema>

export type AuthLoginParamsType = HttpRequestParams<AuthLoginType>
